import React, { useEffect } from 'react'
import { useSelector } from "react-redux";

const Disclaimer = () => {

    const data = useSelector(state => state.AdminReducer)
    let name = data.data.establishment_name ? data.data.establishment_name : data.data.enterprise_name

    useEffect(() => {
        setTimeout(() => { window.scrollTo({ top: '0' }) }, 10)
    }, [])

    return (
        <section id="disclaimer">
            <div id="dsc">
                <p>
                    <h1>Disclaimer voor <a href={`https://www.ondernemersnetwerk.be/business/${data.vat && data.vat.replace(/\./g, '')}/${name}/contact`}>
                        {name}</a></h1>
                </p>
                <p>
                    Op deze pagina vindt u de disclaimer van <a href={`https://www.ondernemersnetwerk.be/business/${data.vat && data.vat.replace(/\./g, '')}/${name}/contact`}>
                        {name}</a>, zoals deze beschikbaar is gesteld door  {name}. In deze disclaimer geven wij
            aan onder welk voorbehoud wij de informatie op onze website aan u aanbieden.
         </p>

                <p>
                    <h2>Intellectueel eigendom </h2>
                </p>

                <p>
                    Het gebruik van de informatie op deze website is gratis zolang u deze informatie niet kopieert, verspreidt of
                    op een andere manier gebruikt of misbruikt. U mag de informatie op deze website alleen hergebruiken volgens
                    de regelingen van het dwingend recht.
         </p><br />

                <p>
                    Zonder uitdrukkelijke schriftelijke toestemming van  {name} is het niet toegestaan tekst,
                    fotomateriaal of andere materialen op deze website her te gebruiken. Het intellectueel eigendom berust bij
                     {name}.
        </p>

                <p>
                    <h2>Geen garantie op juistheid </h2>
                </p>

                <p>
                    <h3>Indien van toepassing: </h3>
                </p><br />

                <p>
                    Voor de prijzen die op onze website staan, geldt dat wij streven naar een zo zorgvuldig mogelijke weergave
                    van de realiteit en de bedoelde prijzen. Fouten die daarbij ontstaan en herkenbaar zijn als programmeer dan
                    wel typefouten, vormen nooit een aanleiding om een contract dan wel overeenkomst met  {name} te
                    mogen claimen of te veronderstellen.
            </p><br />
                <p>
                    {name} streeft naar een zo actueel mogelijke website. Mocht ondanks deze inspanningen de
                    informatie van of de inhoud op deze website onvolledig en of onjuist zijn, dan kunnen wij daarvoor geen
                    aansprakelijkheid aanvaarden.
            </p><br />
                <p>
                    De informatie en/of producten op deze website worden aangeboden zonder enige vorm van garantie en of
                    aanspraak op juistheid. Wij behouden ons het recht voor om deze materialen te wijzigen, te verwijderen of
                    opnieuw te plaatsen zonder enige voorafgaande medeling.  {name} aanvaardt geen aansprakelijkheid
                    voor enige informatie die op websites staat waarnaar wij via hyperlinks verwijzen.
             </p>
                <p>
                    <h2>Wijzigingen </h2>
                </p>
                <p>Mocht deze disclaimer wijzigen, dan vindt u de meest recente versie van de disclaimer van
            <a href={`https://www.ondernemersnetwerk.be/business/${data.vat && data.vat.replace(/\./g, '')}/${name}/contact`}> {name}</a> op deze pagina.
                </p>
            </div>
        </section>
    )
}

export default Disclaimer