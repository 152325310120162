import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateModules } from "../../actions";
import {
  OsnTextarea,
  Button,
  OsnInputText,
} from "@identitybuilding/idb-react-ui-elements";
import axios from "axios";
import Floorplan from "../Floorplan";

const FloorplanEdit = (props) => {
  const dispatch = useDispatch();

  let modules = useSelector((state) => state.AdminReducer.modules);
  let data = useSelector((state) => state.AdminReducer.data);
  let colors = useSelector((state) => state.AdminReducer.colors);

  let [floorplanTitle, setFloorplanTitle] = useState(
    data.options.floorplan.title
  );
  let entityCopy = Object.assign({}, data);
  let modulcesCopy = [...modules];

  const handler = (action, keyword) => {
    if (action === "template") {
      entityCopy.options.floorplan.template = keyword;
      adminChange(keyword, action);
    }
    if (action === "title") {
      entityCopy.options.floorplan.title = keyword;
      adminChange(keyword, action);
    }
    if (action === "theme") {
      entityCopy.options.floorplan.theme = keyword;
      adminChange(keyword, action);
    }

    modulcesCopy[props.index].module = (
      <Floorplan
        template={entityCopy.options.floorplan.template}
        title={entityCopy.options.floorplan.title}
        theme={entityCopy.options.floorplan.theme}
        image={entityCopy.options.floorplan.image}
        width="500px"
      />
    );

    dispatch(updateModules(modulcesCopy));
  };

  const adminChange = (props, action) => {
    axios
      .put(
        `https://delhaize-proxy-muizen-default-rtdb.europe-west1.firebasedatabase.app/data/data/options/floorplan.json`,
        {
          [action]: props,
          ...entityCopy.options.floorplan,
        }
      )
      .then(async (resp) => {
      });
  };

  return (
    <div className="testBlock">
      <div className="testTitle">floorplan</div>
      <div>
        <span>Title:</span>
        <OsnInputText
          onChange={(e) => setFloorplanTitle(e.target.value)}
          value={floorplanTitle}
          placeholder={floorplanTitle}
        />
        <Button
          bgColor={["cn"]}
          size="S"
          text="Change title"
          txtColor="white"
          type="main"
          onClick={() => handler("title", floorplanTitle)}
        />
      </div>
      <div>
        <span>Template:</span>
        <ul>
          <li onClick={() => handler("template", 1)}>1</li>
        </ul>
      </div>
      <div>
        <span>Theme:</span>
        <ul>
          <li onClick={() => handler("theme", "dark")}>dark</li>
          <li onClick={() => handler("theme", "light")}>light</li>
        </ul>
      </div>
    </div>
  );
};
export default FloorplanEdit;
