import { Fragment } from "react";
import { useSelector } from "react-redux";

const EShop = (props) => {
  const {modules, translate, folder_name} = useSelector((state) => state.AdminReducer);
  return (
    // <Fragment>
    //   <section
    //     id="e-shop"
    //     className={[
    //       !props.template ? "temp-1" : "temp-" + props.template,
    //       !props.theme ? "light" : props.theme,
    //     ].join(" ")}
    //   >
    //     <h2>{translate('visit_our_eshop')}</h2>
    //     <div className="e-shop-button__container">
    //       <a
    //         href="https://www.delhaizeproxymuizen.be/extra/webshop/"
    //         className="e-shop-button"
    //       >
    //         {translate('our_eshop')}
    //       </a>
    //       <a
    //         href="https://www.delhaize.be/nl-be/shop"
    //         className="e-shop-button"
    //       >
    //         DELHAIZE E-SHOP
    //       </a>
    //     </div>
    //   </section>
    // </Fragment>
    <section
    id="e-shop"
    className={[
      !props.template ? "temp-1" : "temp-" + props.template,
      !props.theme ? "light" : props.theme,
    ].join(" ")}
  >
    <h2>{translate('visit_our_eshop_now')}</h2>
    <figure><img src={`https://o-sn.be/kml/test-onepages/${folder_name}/webshop-mockup.png`} /></figure>
    <a href={modules.filter((e) => e.name === 'E-Shop')[0].link} target="_blank"><figure className="orderbutton"><img src={require('../library/images/bestel.svg').default} /></figure></a>
    
   
  </section>
  );
};

export default EShop;
