import Icon from "@identitybuilding/idb-react-iconlib";
import axios from "axios";
import { Fragment, useEffect, useState, useRef } from "react";
import Player from "video-react/lib/components/Player";
import Modal from "react-modal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import { useSelector } from "react-redux";

Modal.setAppElement(document.getElementById("fotoalbum"));

const Fotoalbum = () => {
  const [allFiles, setAllFiles] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState(false)
  let data = useSelector((state) => state.AdminReducer.data);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    variableWidth: true,
    autoplay: false,
    swipeToSlide: false,
    draggable: true,
    initialSlide: slideIndex,
  };


  const openModal = (props) => {
    let index = +props._dispatchInstances.key;
    setSlideIndex(index);
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    // let imagePaths = getImagePaths(directory);
    window.scrollTo(0, 0);
    const importAll = (r) => {
      return r.keys().map(r);
    }
    setImages(importAll(require.context('./library/images/fotoalbum/', false, /\.(jpg|JPG|png|jpe?g|svg)$/)))
    setIsLoaded(true)

  }, []);



  return (
    <Fragment>
      {isLoaded === true && (
        <section id="fotoalbum">


          {/* <div className="video-container">
            <Player
              className="video"
              playsInline
              src={require('./library/images/fotoalbum/Delhaize-Muizen-FINALv2-verkleind.mp4').default}
            />
          </div> */}
          <div className="foto-list">
            {
              images.map((image, index) => (
                <div onClick={openModal} key={index} className="image-container">
                  <img className="image" src={image.default} />
                  <div className="overlay">
                    <Icon name="Search" />
                  </div>
                </div>
              ))

            }
          </div>

          <Modal
            className="fotoalbum"
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <Slider {...settings}>
              {images.map((image) => (
                <div key={image} className="image-slider-item">
                  {/* <div style={{
                    backgroundImage: `url('${image.default}')`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    height: "80vh",
                  }}>

                  </div> */}
                  {/* test */}
                  <img
                    className="image"
                    src={image.default}
                  />
                </div>
              ))}
            </Slider>
          </Modal>
        </section>
      )}
      <Footer
        theme={data.options.footer.theme}
        contact={data.options.footer.contact}
        sitemap={data.options.footer.sitemap}
        name={data.establishment_name}
        enterprise_name={data.enterprise_name}
        address={data.address.line_1}
        city={data.address.line_2}
        phone={data.telephone}
        cellphone={
          data.cellphone.length > 0
            ? data.cellphone[0]
            : ""
        }
        vat={data.vat_number}
        socialMedia={data.social_media}
      />
    </Fragment>
  );
};

export default Fotoalbum;
