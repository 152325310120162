import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateModules } from "../../actions";
import Header from "../Header";
import axios from "axios";

import { OsnTextarea, Button } from "@identitybuilding/idb-react-ui-elements";

const HeaderEdit = (props) => {
  const dispatch = useDispatch();

  let modules = useSelector((state) => state.AdminReducer.modules);
  let data = useSelector((state) => state.AdminReducer.data);

  let [sloganText, setSloganText] = useState(data.options.home.slogan);
  let entityCopy = Object.assign({}, data);
  let modulcesCopy = [...modules];

  const handler = (action, keyword) => {
    if (action === "background") {
      if (keyword === "image") {
        entityCopy.options.home.type = "image";
        adminChange(keyword, action);
      }
      if (keyword === "video") {
        entityCopy.options.home.type = "video";
        adminChange(keyword, action);
      }
      if (keyword === "both") {
        entityCopy.options.home.type = "both";
        adminChange(keyword, action);
      }
    }
    if (action === "fullscreen") {
      entityCopy.options.home.fullscreen = keyword;
      adminChange(keyword, action);
    }
    if (action === "logoSloganµvisibility") {
      if (keyword === "visable") {
        entityCopy.options.home.logo_and_slogan_visibility = true;
        adminChange(keyword, action);
      }
      if (keyword === "hidden") {
        entityCopy.options.home.logo_and_slogan_visibility = false;
        adminChange(keyword, action);
      }
      if (keyword === "mix") {
        entityCopy.options.home.logo_and_slogan_visibility = "hidden";
        adminChange(keyword, action);
      }
    }
    if (action === "logoVisibility") {
      entityCopy.options.home.logo_visibility = keyword;
      adminChange(keyword, action);
    }
    if (action === "sloganTemp") {
      entityCopy.options.home.slogan_template = keyword;
      adminChange(keyword, action);
    }
    if (action === "setSloganText") {
      entityCopy.options.home.slogan = keyword;
      adminChange(keyword, action);
    }
    if (action === "height") {
      entityCopy.options.home.height = keyword;
      adminChange(keyword, action);
    }
    if (action === "theme") {
      entityCopy.options.home.theme = keyword;
      adminChange(keyword, action);
    }
    if (action === "opacity") {
      entityCopy.options.home.overlay_opacity = keyword;
      adminChange(keyword, action);
    }

    modulcesCopy[props.index].module = (
      <Header
        type={entityCopy.options.home.type}
        name={"IdentityBuilding"}
        logo={entityCopy.logos.length > 0 ? entityCopy.logos[0].logo : ""}
        slogan={entityCopy.options.home.slogan}
        image={entityCopy.options.home.image}
        video={entityCopy.options.home.video}
        fullscreen={entityCopy.options.home.fullscreen}
        logo_and_slogan_visibility={
          entityCopy.options.home.logo_and_slogan_visibility
        }
        logo_visibility={entityCopy.options.home.logo_visibility}
        height={entityCopy.options.home.height}
        theme={entityCopy.options.home.theme}
        overlay_opacity={entityCopy.options.home.overlay_opacity}
        slogan_template={entityCopy.options.home.slogan_template}
      />
    );

    dispatch(updateModules(modulcesCopy));
    // setModules(modulcesCopy)
  };

  const adminChange = (props, action) => {
    axios
      .put(
        `https://delhaize-proxy-muizen-default-rtdb.europe-west1.firebasedatabase.app/data/data/options/header.json`,
        {
          [action]: props,
          ...entityCopy.options.home,
        }
      )
      .then(async (resp) => {
      });
  };

  return (
    <div className="testBlock">
      <div>
        <span>Header type:</span>
        <ul>
          <li onClick={() => handler("background", "image")}>Image only</li>
          <li onClick={() => handler("background", "video")}>
            Video only (looping video)
          </li>
          <li onClick={() => handler("background", "both")}>
            Video first, then image
          </li>
        </ul>
      </div>
      <div>
        <span>Video fullscreen:</span>
        <ul>
          <li onClick={() => handler("fullscreen", true)}>yes</li>
          <li onClick={() => handler("fullscreen", false)}>no</li>
        </ul>
      </div>
      <div>
        <span>Logo and slogan visibility:</span>
        <ul>
          <li onClick={() => handler("logoSloganµvisibility", "visable")}>
            Show
          </li>
          <li onClick={() => handler("logoSloganµvisibility", "hidden")}>
            Hide
          </li>
          <li onClick={() => handler("logoSloganµvisibility", "mix")}>
            Hide on video, show on image
          </li>
        </ul>
      </div>
      <div>
        <span>Logo visibility:</span>
        <ul>
          <li onClick={() => handler("logoVisibility", true)}>Show</li>
          <li onClick={() => handler("logoVisibility", false)}>Hide</li>
        </ul>
      </div>
      <div>
        <span>Slogan template:</span>
        <ul>
          <li onClick={() => handler("sloganTemp", 1)}>1</li>
          <li onClick={() => handler("sloganTemp", 2)}>2</li>
        </ul>

        <span>Slogan:</span>
        <OsnTextarea
          onChange={(e) => setSloganText(e.target.value)}
          value={sloganText}
          placeholder={sloganText}
        />
        <Button
          bgColor={["cn"]}
          size="S"
          text="Change slogan"
          txtColor="white"
          type="main"
          onClick={() => handler("setSloganText", sloganText)}
        />
        <br />
      </div>
      <div>
        <span>Header height:</span>
        <ul>
          <li onClick={() => handler("height", "full")}>Fullscreen</li>
          <li onClick={() => handler("height", "half")}>Halfscreen</li>
        </ul>
      </div>
      <div>
        <span>Theme:</span>
        <ul>
          <li onClick={() => handler("theme", "dark")}>dark</li>
          <li onClick={() => handler("theme", "main")}>main</li>
          <li onClick={() => handler("theme", "sub")}>sub</li>
          <li onClick={() => handler("theme", "gradient")}>gradient</li>
        </ul>
      </div>
      <div>
        <span>Opacity of overlay colour:</span>
        <select
          value={data.options.home.overlay_opacity}
          onChange={(e) => handler("opacity", e.target.value)}
        >
          <option>0</option>
          <option>0.1</option>
          <option>0.2</option>
          <option>0.3</option>
          <option>0.4</option>
          <option>0.5</option>
          <option>0.6</option>
          <option>0.7</option>
          <option>0.8</option>
          <option>0.9</option>
          <option>1</option>
        </select>
      </div>
    </div>
  );
};

export default HeaderEdit;
