import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateModules } from "../../actions";
import Footer from "../Footer";
import { OsnTextarea, Button } from "@identitybuilding/idb-react-ui-elements";
import axios from "axios";

const FooterEdit = (props) => {
  const dispatch = useDispatch();

  let modules = useSelector((state) => state.AdminReducer.modules);
  let data = useSelector((state) => state.AdminReducer.data);
  let entityCopy = Object.assign({}, data);
  let modulcesCopy = [...modules];

  const handler = (action, keyword) => {
    if (action === "theme") {
      entityCopy.options.footer.theme = keyword;
      adminChange(keyword, action);
    }
    if (action === "contact") {
      entityCopy.options.footer.contact = keyword;
      adminChange(keyword, action);
    }
    if (action === "sitemap") {
      entityCopy.options.footer.sitemap = keyword;
      adminChange(keyword, action);
    }

    modulcesCopy[props.index].module = (
      <Footer
        theme={entityCopy.options.footer.theme}
        contact={entityCopy.options.footer.contact}
        sitemap={entityCopy.options.footer.sitemap}
        name={entityCopy.establishment_name}
        enterprise_name={entityCopy.enterprise_name}
        address={entityCopy.address.line_1}
        city={entityCopy.address.line_2}
        phone={entityCopy.telephone}
        cellphone={
          entityCopy.cellphone.length > 0 ? entityCopy.cellphone[0] : ""
        }
        vat={entityCopy.vat_number}
        socialMedia={entityCopy.options.footer.socialMedia}
      />
    );

    dispatch(updateModules(modulcesCopy));
  };

  const adminChange = (props, action) => {
    axios
      .put(
        `https://delhaize-proxy-muizen-default-rtdb.europe-west1.firebasedatabase.app/data/data/options/footer.json`,
        {
          [action]: props,
          ...entityCopy.options.footer,
        }
      )
      .then(async (resp) => {
      });
  };

  return (
    <div className="testBlock">
      <div className="testTitle">Footer</div>
      <div>
        <span>Theme:</span>
        <ul>
          <li onClick={() => handler("theme", "dark")}>dark</li>
          <li onClick={() => handler("theme", "main")}>main</li>
          <li onClick={() => handler("theme", "sub")}>sub</li>
          <li onClick={() => handler("theme", "light")}>light</li>
        </ul>
      </div>
      <div>
        <span>Show contact?</span>
        <ul>
          <li onClick={() => handler("contact", true)}>yes</li>
          <li onClick={() => handler("contact", false)}>no</li>
        </ul>
      </div>
      <div>
        <span>Show sitemap?</span>
        <ul>
          <li onClick={() => handler("sitemap", true)}>yes</li>
          <li onClick={() => handler("sitemap", false)}>no</li>
        </ul>
      </div>
    </div>
  );
};
export default FooterEdit;
