import React, { useEffect } from 'react'
import { useSelector } from "react-redux";

const PanosBanner = () => {


    return (
        <section id="panosbanner">
            <div className='q8'>
                <div className='q8-child smiles'>
                    <figure className='smile'><img src={require('../library/images/panos-banner/q8-smile-punten.png').default} /></figure>
                    <figure className='app'><img src={require('../library/images/panos-banner/q8-app.png').default} /></figure>
                </div>
                <div className='q8-child q8-content'>
                    <figure><img src={require('../library/images/panos-banner/q8-logo.png').default} /></figure>
                    <h3>Punten sparen met een Smile</h3>
                    <div className='mobile_download_buttons'>
                        <a href="https://play.google.com/store/apps/details?id=be.qeight.app.smiles&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank" rel='noopener noreferer'><img src={require('../library/images/GOOGLE_PLAY_STORE_BADGE_EN.svg').default} /></a>
                        <a href="https://apps.apple.com/be/app/q8-smiles/id1444620987" target="_blank" rel='noopener noreferer'><img src={require('../library/images/APPLE_STORE_BADGE_EN.svg').default} /></a>
                    </div>
                </div>
            </div>
            <div className='panos'>
                <div className='panos-child'>
                    <figure><img src={require('../library/images/panos-banner/panos-nl.png').default} /></figure>
                    <h3><a href="https://www.panos.be/nl" target="_blank" rel='noopener noreferer'>Bekijk onze menu en allergenenlijst online!</a></h3>
                </div>
            </div>
        </section>
    )
}

export default PanosBanner