import { Fragment, useEffect, useState } from "react";
import Magnifier from "react-magnifier";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Floorplan = (props) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const location = useLocation();
  let width, zoom;

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const translate = useSelector((state) => state.AdminReducer.translate);

  const findWindowSize = () => {
    if (location.pathname === "/admin") {
      width = "85%";
      zoom = 0.7;
    } else if (window.innerWidth < 1400 && window.innerWidth > 900) {
      width = "800px";
      zoom = 0.5;
    } else if (window.innerWidth < 900 && window.innerWidth > 550) {
      width = "500px";
      zoom = 0.5;
    } else if (window.innerWidth < 550) {
      width = "95%";
      zoom = 0.5;
    } else {
      width = "1200px";
      zoom = 0.5;
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  window.removeEventListener("resize", findWindowSize());

  return (
    <Fragment>
      <section
        id="floorplan"
        className={[
          !props.template ? "temp-1" : "temp-" + props.template,
          !props.theme ? "light" : props.theme,
        ].join(" ")}
      >
        <h2 className="floorplan-title">{translate('our_floorplan')}</h2>
        <Magnifier
          src={props.image}
          width={width}
          zoomFactor={zoom}
          mgHeight={300}
          mgWidth={300}
        />
      </section>
    </Fragment>
  );
};

export default Floorplan;
