import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateModules } from "../../actions";
import {
  OsnTextarea,
  Button,
  OsnInputText,
} from "@identitybuilding/idb-react-ui-elements";
import JobApplication from "@identitybuilding/idb-react-job-application/dist/components/vacature/VacatureList";
import axios from "axios";

const JobApplicationUsEdit = (props) => {
  const dispatch = useDispatch();
  const [isDone, setIsDone] = useState(false);

  let modules = useSelector((state) => state.AdminReducer.modules);
  let data = useSelector((state) => state.AdminReducer.data);

  let [jobApplicationTitle, setJobApplicationTitle] = useState(
    data.options.job_application.title
  );
  let entityCopy = Object.assign({}, data);
  let modulcesCopy = [...modules];

  const handler = (action, keyword) => {
    if (action === "template") {
      entityCopy.options.job_application.template = keyword;
      adminChange(keyword, action);
    }
    if (action === "title") {
      entityCopy.options.job_application.title = keyword;
      adminChange(keyword, action);
    }
    if (action === "theme") {
      entityCopy.options.job_application.theme = keyword;
      adminChange(keyword, action);
    }

    modulcesCopy[props.index].module = (
      <JobApplication
        template={entityCopy.options.job_application.template}
        theme={entityCopy.options.job_application.theme}
        vacature={entityCopy.options.job_application.vacatures}
        title={entityCopy.options.job_application.title}
      />
    );
    dispatch(updateModules(modulcesCopy));
    // setModules(modulcesCopy);
  };
  const adminChange = (props, action) => {
    axios
      .put(
        `https://onepage-78a00-default-rtdb.europe-west1.firebasedatabase.app/data/data/options/job_application.json`,
        {
          [action]: props,
          ...entityCopy.options.job_application,
        }
      )
      .then(async (resp) => {
      });
  };

  return (
    <div className="testBlock">
      <div className="testTitle">Job application</div>
      <div>
        <span>Title:</span>
        <OsnInputText
          onChange={(e) => setJobApplicationTitle(e.target.value)}
          value={jobApplicationTitle}
          placeholder={jobApplicationTitle}
        />
        <Button
          bgColor={["cn"]}
          size="S"
          text="Change title"
          txtColor="white"
          type="main"
          onClick={() => handler("title", jobApplicationTitle)}
        />
      </div>
      <div>
        <span>Template:</span>
        <ul>
          <li onClick={() => handler("template", 1)}>1</li>
          <li onClick={() => handler("template", 2)}>2</li>
          <li onClick={() => handler("template", 3)}>3</li>
        </ul>
      </div>
      <div>
        <span>Theme:</span>
        <ul>
          <li onClick={() => handler("theme", "dark")}>dark</li>
          <li onClick={() => handler("theme", "light")}>light</li>
        </ul>
      </div>
    </div>
  );
};

export default JobApplicationUsEdit;
