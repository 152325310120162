export const updateModules = (items) => ({
  type: "UPDATE_MODULES",
  items,
});

export const updateData = (data) => ({
  type: "UPDATE_DATA",
  data,
});

export const updateEst = (est) => ({
  type: "UPDATE_EST",
  est,
});

export const updateColors = (colors) => ({
  type: "UPDATE_COLORS",
  colors,
});

export const updateLang = (lang) => ({
  type: "UPDATE_LANG",
  lang,
});

export const updateIsLoaded = (isloaded) => ({
  type: "UPDATE_ISLOADED",
  isloaded,
});
