import React from "react";

const DigitalDisplay = (props) => {
    return (
        <div className="testBlock">
            <div className="testTitle">Digital display</div>
            <div>
                <span>Theme:</span>
                <ul>
                    <li >light</li>
                    <li >dark</li>
                </ul>
            </div>
        </div>
    );
}
export default DigitalDisplay
