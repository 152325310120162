import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateModules } from "../../actions";
import Team from "@identitybuilding/idb-react-team/dist/Team";
import {
  OsnTextarea,
  Button,
  OsnInputText,
} from "@identitybuilding/idb-react-ui-elements";
import axios from "axios";

const TeamEdit = (props) => {
  const dispatch = useDispatch();

  let modules = useSelector((state) => state.AdminReducer.modules);
  let data = useSelector((state) => state.AdminReducer.data);

  let [teamTitle, setTeamTitle] = useState(data.options.team.title);
  let [teamDesciption, setTeamDesciption] = useState(data.options.team.text);

  let entityCopy = Object.assign({}, data);
  let modulcesCopy = [...modules];
  const handler = (action, keyword) => {
    if (action === "title") {
      entityCopy.options.team.title = keyword;
      adminChange(keyword, action);
    }
    if (action === "text") {
      entityCopy.options.team.description = keyword;
      adminChange(keyword, action);
    }
    if (action === "template") {
      entityCopy.options.team.template = keyword;
      adminChange(keyword, action);
    }
    if (action === "theme") {
      entityCopy.options.team.theme = keyword;
      adminChange(keyword, action);
    }
    if (action === "social_media") {
      entityCopy.options.team.social_media = keyword;
      adminChange(keyword, action);
    }
    if (action === "hover") {
      entityCopy.options.team.hover_animation = keyword;
      adminChange(keyword, action);
    }

    modulcesCopy[props.index].module = (
      <Team
        template={entityCopy.options.team.template}
        theme={entityCopy.options.team.theme}
        team={entityCopy.options.team.team}
        socialMedia={entityCopy.options.team.social_media}
        hover_animation={entityCopy.options.team.hover_animation}
        title={entityCopy.options.team.title}
        description={entityCopy.options.team.description}
      />
    );

    dispatch(updateModules(modulcesCopy));
    // setModules(modulcesCopy)
  };

  const adminChange = (props, action) => {
    axios
      .put(
        `https://onepage-78a00-default-rtdb.europe-west1.firebasedatabase.app/data/data/options/team.json`,
        {
          [action]: props,
          ...entityCopy.options.team,
        }
      )
      .then(async (resp) => {
      });
  };

  return (
    <div className="testBlock">
      <div className="testTitle">Team</div>
      {entityCopy.options.team.title !== "" && (
        <div>
          <span>Title:</span>
          <OsnInputText
            onChange={(e) => setTeamTitle(e.target.value)}
            value={teamTitle}
            placeholder={teamTitle}
          />
          <Button
            bgColor={["cn"]}
            size="S"
            text="Change title"
            txtColor="white"
            type="main"
            onClick={() => handler("title", teamTitle)}
          />
        </div>
      )}
      <div>
        <span>Description:</span>
        <OsnTextarea
          onChange={(e) => setTeamDesciption(e.target.value)}
          value={teamDesciption}
          placeholder={teamDesciption}
        />
        <Button
          bgColor={["cn"]}
          size="S"
          text="Change description"
          txtColor="white"
          type="main"
          onClick={() => handler("text", teamDesciption)}
        />
      </div>
      <div>
        <span>Template:</span>
        <ul>
          <li onClick={() => handler("template", 1)}>1</li>
          <li onClick={() => handler("template", 2)}>2</li>
          <li onClick={() => handler("template", 3)}>3</li>
          <li onClick={() => handler("template", 4)}>4</li>
          <li onClick={() => handler("template", 5)}>5</li>
        </ul>
      </div>
      <div>
        <span>Theme:</span>
        <ul>
          <li onClick={() => handler("theme", "dark")}>dark</li>
          <li onClick={() => handler("theme", "main")}>main</li>
          <li onClick={() => handler("theme", "sub")}>sub</li>
          <li onClick={() => handler("theme", "light")}>light</li>
        </ul>
      </div>
      <div>
        <span>Social media:</span>
        <ul>
          <li onClick={() => handler("social_media", true)}>yes</li>
          <li onClick={() => handler("social_media", false)}>no</li>
        </ul>
      </div>
      <div>
        <span>Hover animation:</span>
        <ul>
          <li onClick={() => handler("hover", "grow")}>grow</li>
          <li onClick={() => handler("hover", "grey-to-colour")}>
            grey-to-colour
          </li>
          <li onClick={() => handler("hover", "circle-to-square")}>
            circle-to-square
          </li>
          <li onClick={() => handler("hover", "square-to-circle")}>
            square-to-circle
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TeamEdit;
