import React, { useEffect } from 'react'
import { useSelector } from "react-redux";

const ToGoodToGo = () => {


    return (
        <section id="toogoodtogo">
            <h3>RED VOEDSEL<br/>HELP DE PLANEET</h3>
            <figure><img src={require('../library/images/toogoodtogo/maaltijden.png').default} /></figure>
            <div className='mobile_download_buttons'>
                <a href="https://play.google.com/store/apps/details?id=com.app.tgtg&hl=nl&pli=1" target="_blank" rel='noopener noreferer'><img src={require('../library/images/GOOGLE_PLAY_STORE_BADGE_EN.svg').default}/></a>
                <a href="https://apps.apple.com/be/app/too-good-to-go/id1060683933" target="_blank" rel='noopener noreferer'><img src={require('../library/images/APPLE_STORE_BADGE_EN.svg').default}/></a>
            </div>
        </section>
    )
}

export default ToGoodToGo