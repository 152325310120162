import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import Icon
 from '@identitybuilding/idb-react-iconlib';
const HappyGateau = () => {

    const { lang } = useSelector((state) => state.AdminReducer);

    return (
        <section id="happygateau">
            <div className="logo"></div>
            <a href={`https://happygateau.lalorraine.be/${lang}`} target="_blank" rel='noopener noreferer'>
                {lang === 'nl' ?
                    <p>
                        Maak jouw eigen gepersonaliseerde taart.
                        <br />
                        <br />
                        Bestel minstens 48u op voorhand!
                    </p>
                    :
                    <p>
                        Faites votre propre gâteau personnalisé.
                        <br />
                        <br />
                        Commandez au moins 48 heures à l'avance !
                    </p>
                }
                <Icon name="ArrowDown"/>
            </a>
        </section>
    )
}

export default HappyGateau