import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateModules } from "../../actions";
import CurrentOffers from "@identitybuilding/idb-react-current-offers";
import axios from "axios";

const CurrentOffersEdit = (props) => {
  const dispatch = useDispatch();

  let modules = useSelector((state) => state.AdminReducer.modules);
  let data = useSelector((state) => state.AdminReducer.data);
  let est = useSelector((state) => state.AdminReducer.est);
  let lang = useSelector((state) => state.AdminReducer.lang);
  let entityCopy = Object.assign({}, data);
  let modulcesCopy = [...modules];

  let [offerTitle, setOfferTitle] = useState(data.options.floorplan.title);

  let [creadCollections, setCreadCollections] = useState([]);

  const handler = (action, keyword) => {
    if (action === "theme") {
      entityCopy.options.current_offers.theme = keyword;
      adminChange(keyword, action);
    }
    if (action === "title") {
      entityCopy.options.floorplan.title = keyword;
      adminChange(keyword, action);
    }

    modulcesCopy[props.index].module = (
      <CurrentOffers
        id={est}
        lang={lang}
        creadCollections={creadCollections}
        setCreadCollections={(e) => setCreadCollections(e)}
        title={'our_story'}
      />
    );

    dispatch(updateModules(modulcesCopy));
  };

  const adminChange = (props, action) => {
    axios
      .put(
        `https://delhaize-proxy-muizen-default-rtdb.europe-west1.firebasedatabase.app/data/data/options/current_offers.json`,
        {
          [action]: props,
          ...entityCopy.options.current_offers,
        }
      )
      .then(async (resp) => {
      });
  };

  return (
    <div className="testBlock">
      <div className="testTitle">Current offers</div>
      <div>
        <span>Theme:</span>
        <ul>
          <li onClick={() => handler("theme", "dark")}>dark</li>
          <li onClick={() => handler("theme", "light")}>light</li>
        </ul>
      </div>
    </div>
  );
};
export default CurrentOffersEdit;
