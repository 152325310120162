const ContactModel = (json, lang) => {
  let data = {};
  data.social_media = [];
  data.registration_numbers = [];
  data.mobile_phone = [];
  data.telephone = [];

  let has_website = false;
  // Contact ways
  if (json.contacts.length > 0) {
    json.contacts.forEach((contact_link) => {
      let contact = contact_link.contact_method;
      if (contact.medium.toUpperCase() === "WEBSITE") {
        has_website = true;
        contact.value !== window.location.origin &&
        contact.value &&
          data.social_media.push({
            name: "Website",
            url:
              contact.value[6] === "/"
                ? contact.value
                : `https://${contact.value}`,
            icon: "Website",
          });
        // Set website on resultspage (WIP because this will always be the last website that loads in)
        if (contact.value && contact.value[6] === "/") {
          data.website = contact.value;
        } else {
          data.website = "https://" + contact.value;
        }
      }
      if (contact.medium.toUpperCase() === "FAX") {
        if (contact.value) {
          data.fax = contact.value;
        }
      }
      if (contact.medium.toUpperCase() === "FIXED") {
        // if (contact.telephone_subtype === 'FIXED') { data.telephone = contact.telephone }
        if (data.telephone.length < 2) {
          data.telephone.push(contact.value);
        }
      }
      if (contact.medium.toUpperCase() === "MOBILE") {
        if (data.mobile_phone.length < 2) {
          if (!data.mobile_phone.includes(contact.mobile_phone)) {
            data.mobile_phone.push(contact.value);
          }
        }
      }

      if (contact.medium.toUpperCase() === "EMAIL") {
        data.social_media.push({
          email: contact.value,
          name: "Mail",
          icon: "Mail",
        });
      }
      if (contact.medium.toUpperCase() === "FACEBOOK") {
        data.social_media.push({
          name: "Facebook",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Facebook",
        });
      }
      if (contact.medium.toUpperCase() === "INSTAGRAM") {
        data.social_media.push({
          name: "Instagram",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Instagram",
        });
      }
      if (contact.medium.toUpperCase() === "LINKEDIN") {
        data.social_media.push({
          name: "LinkedIn",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Linkedin",
        });
      }
      if (contact.medium.toUpperCase() === "TWITTER") {
        data.social_media.push({
          name: "Twitter",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Twitter",
        });
      }
      if (contact.medium.toUpperCase() === "PINTEREST") {
        data.social_media.push({
          name: "Pinterest",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Pinterest",
        });
      }
      if (contact.medium.toUpperCase() === "YOUTUBE") {
        data.social_media.push({
          name: "YouTube",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Youtube",
        });
      }
      if (contact.medium.toUpperCase() === "FLICKR") {
        data.social_media.push({
          name: "Flickr",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Flickr",
        });
      }
      if (contact.medium.toUpperCase() === "WHATSAPP") {
        data.social_media.push({
          name: "WhatsApp",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Whatsapp",
        });
      }
      if (contact.medium.toUpperCase() === "WEBSHOP") {
        data.social_media.push({
          name: "Webshop",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Eshop",
        });
      }

      if (contact.medium.toUpperCase() === "NEWSLETTER") {
        contact.newsletter &&
          data.social_media.push({
            name: "Newsletter",
            url:
              contact.newsletter[6] === "/"
                ? contact.newsletter
                : `https://${contact.newsletter}`,
            icon: "Newsletter",
          });
      }
    });
  }

  if (json.enterprise_contacts.length > 0) {
    json.enterprise_contacts.forEach((contact_link) => {
      let contact = contact_link.contact_method;
      if (contact.medium.toUpperCase() === "WEBSITE") {
        has_website = true;
        contact.value !== window.location.origin &&
        contact.value &&
          data.social_media.push({
            name: "Website",
            url:
              contact.value[6] === "/"
                ? contact.value
                : `https://${contact.value}`,
            icon: "Website",
          });
        // Set website on resultspage (WIP because this will always be the last website that loads in)
        if (contact.value && contact.value[6] === "/") {
          data.website = contact.value;
        } else {
          data.website = "https://" + contact.value;
        }
      }
      if (contact.medium.toUpperCase() === "FAX") {
        if (contact.value) {
          data.fax = contact.value;
        }
      }
      if (contact.medium.toUpperCase() === "FIXED") {
        // if (contact.telephone_subtype === 'FIXED') { data.telephone = contact.telephone }
        if (data.telephone.length < 2) {
          data.telephone.push(contact.value);
        }
      }
      if (contact.medium.toUpperCase() === "MOBILE") {
        if (data.mobile_phone.length < 2) {
          if (!data.mobile_phone.includes(contact.mobile_phone)) {
            data.mobile_phone.push(contact.value);
          }
        }
      }

      if (contact.medium.toUpperCase() === "EMAIL") {
        data.social_media.push({
          email: contact.value,
          name: "Mail",
          icon: "Mail",
        });
      }
      if (contact.medium.toUpperCase() === "FACEBOOK") {
        data.social_media.push({
          name: "Facebook",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Facebook",
        });
      }
      if (contact.medium.toUpperCase() === "INSTAGRAM") {
        data.social_media.push({
          name: "Instagram",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Instagram",
        });
      }
      if (contact.medium.toUpperCase() === "LINKEDIN") {
        data.social_media.push({
          name: "LinkedIn",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Linkedin",
        });
      }
      if (contact.medium.toUpperCase() === "TWITTER") {
        data.social_media.push({
          name: "Twitter",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Twitter",
        });
      }
      if (contact.medium.toUpperCase() === "PINTEREST") {
        data.social_media.push({
          name: "Pinterest",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Pinterest",
        });
      }
      if (contact.medium.toUpperCase() === "YOUTUBE") {
        data.social_media.push({
          name: "YouTube",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Youtube",
        });
      }
      if (contact.medium.toUpperCase() === "FLICKR") {
        data.social_media.push({
          name: "Flickr",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Flickr",
        });
      }
      if (contact.medium.toUpperCase() === "WHATSAPP") {
        data.social_media.push({
          name: "WhatsApp",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Whatsapp",
        });
      }
      if (contact.medium.toUpperCase() === "WEBSHOP") {
        data.social_media.push({
          name: "Webshop",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Eshop",
        });
      }

      if (contact.medium.toUpperCase() === "NEWSLETTER") {
        contact.newsletter &&
          data.social_media.push({
            name: "Newsletter",
            url:
              contact.newsletter[6] === "/"
                ? contact.newsletter
                : `https://${contact.newsletter}`,
            icon: "Newsletter",
          });
      }
    });
  }

  if (json.group_contacts.length > 0) {
    json.group_contacts.forEach((contact_link) => {
      let contact = contact_link.contact_method;
      if (contact.medium.toUpperCase() === "WEBSITE") {
        has_website = true;
        contact.value !== window.location.origin &&
        contact.value &&
          data.social_media.push({
            name: "Website",
            url:
              contact.value[6] === "/"
                ? contact.value
                : `https://${contact.value}`,
            icon: "Website",
          });
        // Set website on resultspage (WIP because this will always be the last website that loads in)
        if (contact.value && contact.value[6] === "/") {
          data.website = contact.value;
        } else {
          data.website = "https://" + contact.value;
        }
      }
      if (contact.medium.toUpperCase() === "FAX") {
        if (contact.value) {
          data.fax = contact.value;
        }
      }
      if (contact.medium.toUpperCase() === "FIXED") {
        // if (contact.telephone_subtype === 'FIXED') { data.telephone = contact.telephone }
        if (data.telephone.length < 2) {
          data.telephone.push(contact.value);
        }
      }
      if (contact.medium.toUpperCase() === "MOBILE") {
        if (data.mobile_phone.length < 2) {
          if (!data.mobile_phone.includes(contact.mobile_phone)) {
            data.mobile_phone.push(contact.value);
          }
        }
      }

      if (contact.medium.toUpperCase() === "EMAIL") {
        data.social_media.push({
          email: contact.value,
          name: "Mail",
          icon: "Mail",
        });
      }
      if (contact.medium.toUpperCase() === "FACEBOOK") {
        data.social_media.push({
          name: "Facebook",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Facebook",
        });
      }
      if (contact.medium.toUpperCase() === "INSTAGRAM") {
        data.social_media.push({
          name: "Instagram",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Instagram",
        });
      }
      if (contact.medium.toUpperCase() === "LINKEDIN") {
        data.social_media.push({
          name: "LinkedIn",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Linkedin",
        });
      }
      if (contact.medium.toUpperCase() === "TWITTER") {
        data.social_media.push({
          name: "Twitter",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Twitter",
        });
      }
      if (contact.medium.toUpperCase() === "PINTEREST") {
        data.social_media.push({
          name: "Pinterest",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Pinterest",
        });
      }
      if (contact.medium.toUpperCase() === "YOUTUBE") {
        data.social_media.push({
          name: "YouTube",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Youtube",
        });
      }
      if (contact.medium.toUpperCase() === "FLICKR") {
        data.social_media.push({
          name: "Flickr",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Flickr",
        });
      }
      if (contact.medium.toUpperCase() === "WHATSAPP") {
        data.social_media.push({
          name: "WhatsApp",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Whatsapp",
        });
      }
      if (contact.medium.toUpperCase() === "WEBSHOP") {
        data.social_media.push({
          name: "Webshop",
          url:
            contact.value[6] === "/"
              ? contact.value
              : `https://${contact.value}`,
          icon: "Eshop",
        });
      }

      if (contact.medium.toUpperCase() === "NEWSLETTER") {
        contact.newsletter &&
          data.social_media.push({
            name: "Newsletter",
            url:
              contact.newsletter[6] === "/"
                ? contact.newsletter
                : `https://${contact.newsletter}`,
            icon: "Newsletter",
          });
      }
    });
  }

  // Function that gives unique values in an array (example Delhaize --> multiple social media's if not used)
  const uniq = (a) => {
    var seen = {};
    return a.filter((item) => {
      // With an exception for website
      if (item.name === "Website") {
        // return true
        // for website check on url instead of name
        return seen.hasOwnProperty(item.url) ? false : (seen[item.url] = true);
      }

      // filter out the duplicate items and return the uniques.
      else {
        return seen.hasOwnProperty(item.name)
          ? false
          : (seen[item.name] = true);
      }

      // return seen.hasOwnProperty(item.name) ? false : (seen[item.name] = true);
    });
  };

  let uniqValues = uniq(data.social_media);

  data.social_media = uniqValues;

  // Sort the social media icons based on this priority list

  const priority = [
    "Mail",
    "Website",
    "Newsletter",
    "E-shop",
    "Webshop",
    "Facebook",
    "Instagram",
    "WhatsApp",
    "YouTube",
    "Pinterest",
    "LinkedIn",
    "Twitter",
    "Flickr",
  ];

  const sorted_socialMedia = data.social_media.sort((a, b) => {
    if (priority.indexOf(a.name) > priority.indexOf(b.name)) return 1;
    if (priority.indexOf(a.name) < priority.indexOf(b.name)) return -1;
    return 0;
  });
  data.social_media = sorted_socialMedia;

  return data;
};

export default ContactModel;
